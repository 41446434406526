import * as React from "react";
import { AuthContext } from "./AuthContext";
import { CoderOneRoute } from "../Pages/CoderOneRoute";
import { Redirect } from "@reach/router";
import { useContext } from "react";
import { useHasMounted } from "../../hooks/useHasMounted";
interface IProps {
    readonly route?: string;
    readonly fallback?: JSX.Element;
}

export const WithoutAuth: React.FC<React.PropsWithChildren<IProps>> = ({ children, route, fallback }) => {
    const { token } = useContext(AuthContext);
    const hasMounted = useHasMounted();
    if (!hasMounted) {
        return <React.Fragment>{children}</React.Fragment>;
    }
    if (token !== null) {
        return <React.Fragment>{fallback ?? <Redirect from="*" to={route || CoderOneRoute.Dashboard} noThrow />}</React.Fragment>;
    }
    return <React.Fragment>{children}</React.Fragment>;
};
