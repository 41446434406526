import styled from "styled-components";

export const Form = styled.form`
    display: grid;
    grid-row-gap: 16px;
`;

export const PreferenceForm = styled.form`
    display: grid;
    grid-row-gap: 16px;
    grid-template-columns: auto min-content;
    grid-template-rows: 16px auto;
    align-items: end;
    gap: 8px;
`;
