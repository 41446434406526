import * as React from "react";
import axios from "axios";
import { AuthContext } from "../../../Auth/AuthContext";
import { Constants } from "../../../../utilities/Constants";
import { Text } from "../../../Text/Text";
import { LoginFormContainer, LoginRoot, Heading } from "./Login.styles";
import { LogoSymbol } from "../../../LogoSymbol/LogoSymbol";
import { RouterLink } from "../../../RouterLink/RouterLink";
import { SEO } from "../../../SEO/SEO";
import { Spinner } from "../../../Spinner/Spinner";
import { Form } from "../../../Form/Form";
import { StateButton } from "../../../StateButton/StateButton";
import { telemetry } from "../../../../utilities/Telemetry/Telemetry";
import { TelemetryEvent } from "../../../../utilities/Telemetry/TelemetryEvent";
import { TextInput } from "../../../TextInput/TextInput";
import { Trans, useTranslation } from "react-i18next";
import { useCallback, useContext, useMemo, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { WithoutAuth } from "../../../Auth/WithoutAuth";
import { Palette } from "../../../../theme/Palette";
import { CoderOneRoute } from "../../../Pages/CoderOneRoute";
import { getAxiosRequestConfig } from "../../../../utilities/getAxiosRequestConfig";
import { useSessionStorage } from "../../../../hooks/useSessionStorage";
import { SessionStorageKey } from "../../../../utilities/SessionStorageKey";
import { IOrderDetails } from "@coderone/library";

interface IPostSessionResponse {
    readonly token: string;
}

interface ILoginForm {
    readonly email: string;
    readonly password: string;
    readonly order?: IOrderDetails;
}

const postSession = async (form: ILoginForm): Promise<IPostSessionResponse> => {
    const request = axios.post(`${Constants.ApiRoot}/session`, form, getAxiosRequestConfig());

    const { data } = await request;
    return data;
};

const Login: React.FC<React.PropsWithChildren<unknown>> = () => {
    const { setToken } = useContext(AuthContext);
    const [orderDetails, setOrderDetails] = useSessionStorage(SessionStorageKey.PaypalOrderDetails, null);
    const [t] = useTranslation();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [submissionError, setSubmissionError] = useState<string | undefined>(undefined);

    const onEmailChanged = useCallback(
        (_: unknown, newValue: string | undefined) => {
            const updatedValue = newValue === undefined ? "" : newValue;
            setEmail(updatedValue);
        },
        [setEmail]
    );

    const onPasswordChanged = useCallback(
        (_: unknown, newValue: string | undefined) => {
            const updatedValue = newValue === undefined ? "" : newValue;
            setPassword(updatedValue);
        },
        [setPassword]
    );

    const passwordErrorMessage = useMemo(() => {
        if (password.length <= 0 || password.length >= 8) {
            return undefined;
        }
        return t("passwordMinLengthError");
    }, [t, password]);


    const { mutate, status } = useMutation(postSession, {
        onSuccess: (data) => {
            setToken?.(data.token);
            setOrderDetails(null);
            telemetry.Log(TelemetryEvent.LoginSuccess, null);
        },
        onError: (error, _variables, _context) => {
            const e = error as any;
            const loginError = e?.response?.data?.error;
            setSubmissionError(loginError);
            telemetry.Log(TelemetryEvent.LoginError, loginError);
        },
    });

    const isLoading = useMemo(() => {
        return status === "loading";
    }, [status]);

    const isSubmitDisabled = useMemo(() => {
        if (email.length <= 0 || password.length <= 0 || passwordErrorMessage !== undefined || isLoading) {
            return true;
        }
        return false;
    }, [email, password, passwordErrorMessage, isLoading]);
    
    const onSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
        (e) => {
            e.preventDefault();
            const order = typeof orderDetails === "string" ? (JSON.parse(orderDetails) as IOrderDetails) : null;
            mutate({
                email,
                password,
                ...(order !== null && { order }),
            });
            telemetry.Log(TelemetryEvent.LoginInit, null);
        },
        [email, password, mutate]
    );

    const loginHeading = orderDetails !== null ? t("loginPage.linkAccountToSubscription") : t("login");

    return (
        <WithoutAuth>
            <SEO description={t("login")} title={t("loginToCoderOne")} />
            <LoginRoot>
                <RouterLink href={CoderOneRoute.Home}>
                    <LogoSymbol />
                </RouterLink>
                <LoginFormContainer>
                    <Heading>{loginHeading}</Heading>
                    <Form onSubmit={onSubmit}>
                        <TextInput placeholder={t("email")} value={email} onChange={onEmailChanged} readOnly={isLoading} />
                        <TextInput placeholder={t("password")} type="password" value={password} onChange={onPasswordChanged} readOnly={isLoading} />
                        <StateButton type="submit" disabled={isSubmitDisabled}>
                            {isLoading ? <Spinner fontSize="1em" /> : t("login")}
                        </StateButton>
                        {submissionError !== undefined && <Text color={Palette.Error100}>{submissionError}</Text>}
                    </Form>
                    <p>
                        <Trans key="noAccountSignup">
                            No account? <RouterLink href={CoderOneRoute.Signup}>Sign up</RouterLink>
                        </Trans>
                    </p>
                </LoginFormContainer>
            </LoginRoot>
        </WithoutAuth>
    );
};

export default Login;
